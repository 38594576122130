// @ts-strict-ignore
import { FormikHelpers as FormikActions } from 'formik'

import { CheckoutCrossSell } from '~/utils/checkoutFlow'

import { LeanCrossSellFragment, LeanReservableExtraFragment, ReservationSummaryQuery, VoucherDataQuery } from '~/generated/graphql'

import { getInitialValues } from './CheckoutPaymentSection/PaymentForm/PaymentFormUtils'

export type CheckoutMode = 'edit' | 'create'
export type CheckoutReservation = NonNullable<ReservationSummaryQuery['reservation']>
export type CheckoutVoucher = NonNullable<VoucherDataQuery['voucher']>
export type CheckoutReservable = NonNullable<CheckoutReservation['reservable']>
export type ReservationCrossSells = CheckoutReservation['reservationCrossSells']
export type PreviousCrossSell = {
  quantity: number
  crossSell: { id: string }
}
export type AvailableCrossSells = LeanCrossSellFragment[]
export type AvailableResevableExtras = LeanReservableExtraFragment[]
export type DebouncedUpdate = (
  props: { vars?: any; onCompleted?: () => {}; onError?: () => {}; hideToast?: boolean; forceUpdatePreview?: boolean },
  res?: any
) => void
export type PurchaseReservation = (
  values?: ReturnType<typeof getInitialValues>,
  actions?: FormikActions<ReturnType<typeof getInitialValues>>
) => void

export type ParamsType = {
  slot: number
  date: string
  peopleCount: number
  code: string
}

export enum CheckoutStepsEnum {
  Additions = 'additions',
  Guest = 'guest-details',
  Payment = 'payment',
}

export enum PaymentMethod {
  Card = 'CARD',
  BLIK = 'BLIK',
  ApplePay = 'APPLE_PAY',
  GooglePay = 'GOOGLE_PAY',
  PBL = 'PBL',
  PayPo = 'PAYPO',
  Klarna = 'KLARNA',
  VisaMobile = 'VISA_MOBILE',
}

export type CheckoutActiveSteps = Record<CheckoutStepsEnum, boolean>

export type CommonCheckoutPagesProps = {
  reservation: NonNullable<ReservationSummaryQuery['reservation']>
  previousRoute?: string | null
}

export enum CheckoutFlow {
  D2D = 'd2d',
  CHM = 'chm',
}

export type MinimumReservable =
  | {
      __typename: CheckoutReservable['__typename']
      crossSells: Pick<CheckoutCrossSell, 'festivalDrink'>[]
    }
  | {
      __typename: CheckoutReservable['__typename']
    }
