// @ts-strict-ignore
import slugify from 'slugify'

import { CheckoutFlow } from '~/components/Checkout/CheckoutPage/CheckoutPageTypes'
import config from '~/config'
import { getClubConfig } from '~/config/clubConfig'
import { PolymorphicCollection, ReservableExtra, Reservation, Restaurant } from '~/generated/graphql'

export const pathFragments = {
  chefsMenuProfile: '',
  restaurantProfile: '',
  restaurants: 'restaurants',
  chefsMenuOffers: 'menu',
  festivalRestaurants: 'restaurants/festival',
  blogPost: 'blog/post',
  festival: (festivalSlug: string) => `restaurants/festival/${festivalSlug}`,
  restaurantWeek: 'restaurants/festival/restaurant-week',
  sideEvents: 'side-events-listing',
  checkout: (code: Reservation['code'], subpath: string) => `/order/${subpath}?code=${code}`,
  faq: 'faq',
}

export const paths = {
  home: '/',
  about: '/about',
  partners: '/partners',
  forRestaurateurs: '/for-restaurateurs',
  help: '/help',
  career: '/career',
  festivals: '/festivals',
  restaurants: getClubConfig().restaurantsListingEnabled ? '/restaurants' : '/restaurants/festival/restaurant-week',
  earlyReservation: (festivalEditionId: number | string) => `/early-reservation/${festivalEditionId}`,
  festivalListing: (festival: string, ferId?: string) => {
    let url = `/restaurants/festival/${festival}`
    if (ferId) {
      url += `?ferId=${ferId}`
    }
    return url
  },
  singleFestival: (slug: string) => `/${pathFragments.festivalRestaurants}/${slug}`,
  singleRestaurant: ({ slug }: Pick<Restaurant, 'slug'>) => `${pathFragments.restaurantProfile}/${slug}`,
  singleCollection: ({ id, title }: Pick<PolymorphicCollection, 'id' | 'title'>) =>
    `/collection/${id}-${slugify(title.toLocaleLowerCase())}`,
  chefsMenuOffers: '/menu',
  chefsMenuRestaurant: (restaurant?: string) => {
    let url = '/menu'
    if (restaurant) {
      url += `?restaurant=${restaurant}`
    }
    return url
  },
  singleChefsMenu: ({ slug }: Pick<ReservableExtra, 'slug'>) => `${pathFragments.chefsMenuProfile}/${slug}`,
  singleSideEvent: (id: number | string) => `/side-events/${id}`,
  reservations: '/user/reservations',
  userInformation: '/user/information',
  paymentOptions: '/user/payment-options',
  paymentOptionsSuccess: '/user/payment-options/success',
  paymentOptionsFailure: '/user/payment-options/failure',
  favorites: '/user/favorites',
  settings: '/user/settings',
  festivalLanding: (festival: string) => {
    if (festival === 'restaurant-week') {
      return '/restaurantweek'
    }

    if (festival === 'breakfast-week') {
      return '/breakfastweek'
    }

    return `/${festival}`
  },
  rwLandingPagePartners: '/restaurantweek#logos',
  reservationDetails: code => `/user/reservations/details?code=${code}`,
  reservationEdit: code => `/user/reservations/edit?code=${code}`,
  reservationEditOld: code => `${config().oldUrl}/reservation-edit?prevReservationCode=${code}`,
  checkoutSuccess: (code: string, type?: string, method?: string) => {
    let url = `/order/result/success?code=${code}`
    if (type === 'Discount') {
      url += `&type=${type}`
    }

    if (method) {
      url += `&method=${method}`
    }

    return url
  },
  checkout: (code: string) => `/order?code=${code}`,
  checkoutFestivalMenu: (code: Reservation['code'], editMode?: boolean) =>
    `${pathFragments.checkout(code, 'festival-menu')}${editMode ? '&editMode=1' : ''}`,
  checkoutChefsMenu: (code: Reservation['code'], flow: CheckoutFlow = CheckoutFlow.D2D, editMode?: boolean) =>
    `${pathFragments.checkout(code, 'chefs-menu')}&flow=${flow}${editMode ? '&editMode=1' : ''}`,
  checkoutComplimentaryCrossSells: (code: Reservation['code'], flow?: CheckoutFlow, editMode?: boolean) =>
    `${pathFragments.checkout(code, 'complimentary-cross-sells')}${flow ? `&flow=${flow}` : ''}${editMode ? '&editMode=1' : ''}`,
  checkoutCrossSells: (code: Reservation['code'], flow?: CheckoutFlow, editMode?: boolean) =>
    `${pathFragments.checkout(code, 'cross-sells')}${flow ? `&flow=${flow}` : ''}${editMode ? '&editMode=1' : ''}`,
  checkoutError: (code: string, error: string) => `/order/summary?code=${code}&error=${error}`,
  checkoutFailure: (code: string) => `/order/result/failure?code=${code}`,
  checkoutSummary: (code: Reservation['code'], flow?: CheckoutFlow, editMode?: boolean) =>
    `${pathFragments.checkout(code, 'summary')}${flow ? `&flow=${flow}` : ''}${editMode ? '&editMode=1' : ''}`,
  checkoutGuest: (code: Reservation['code'], flow?: CheckoutFlow, editMode?: boolean) =>
    `${pathFragments.checkout(code, 'guest')}${flow ? `&flow=${flow}` : ''}${editMode ? '&editMode=1' : ''}`,
  privacyPolicy: '/privacy-policy',
  regulations: '/regulations',
  faq: '/faq',
  blog: '/weekly',
  blogPost: (slug: string) => `/weekly/${slug}`,
  giftEventsListing: '/gift-events-listing',
  sideEventsListing: '/side-events-listing',
  voucherDetails: code => `/voucher-details/${code}`,
  voucherOrder: code => `/voucher-order/${code}`,
  error404: '/404',

  // mobile restaurant festival menu (+ drinks)
  mobileRestaurantMenuRedirect: '/restaurant-festival-menu',
  festivalRegions: '/restaurant-festival-menu/regions',
  festivalRegionRestaurants: (regionId: string) => `/restaurant-festival-menu/regions/${regionId}`,
  restaurantFestivalMenu: ({ regionId, slug }: { regionId: string; slug: string }) =>
    `/restaurant-festival-menu/regions/${regionId}/${slug}`,
  error401: (source: string) => `/401?source=${source}`,
  oldFestivalUrl: (festivalSlug: string) => `${config().oldUrl}/${festivalSlug}`,
}

export const externalPaths = {
  rwLandingPage: 'https://restaurantweek.pl/',
  bwLandingPage: 'https://breakfastweek.pl/',
  iosApp: 'https://apps.apple.com/pl/app/restaurant-club/id1490565530',
  androidApp: 'https://play.google.com/store/apps/details?id=pl.restaurantweek.restaurantclub',
}

export const mobileNavigationPaths = [paths.restaurants, paths.chefsMenuOffers, paths.festivals, paths.blog, pathFragments.blogPost]

export const inUserPanel = (route: string) => route.startsWith('/user/')

export const faqQuestionLink = (question: string) => `${paths.faq}?question=${question}`

export const getCanonicalForPath = (path: string) => config().clubUrl + path
