import styled from '@emotion/styled'

import { mediaQueryDown, mediaQueryUp } from '~/theme/utils/grid'
import { Body2, Heading5 } from '~/theme/utils/typography'

import Checkbox from '~/shared/atoms/Checkbox'
import { Header } from '~/shared/atoms/Collapse/CollapseStyle'
import Icon from '~/shared/atoms/Icon'
import Input from '~/shared/atoms/Input'
import { Container, Wrapper as InputWrapper } from '~/shared/atoms/Input/InputShards'
import { StyledToast } from '~/shared/atoms/Toast'

export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
`

export const LoginTextContainer = styled.div`
  display: block;
`

export const LoginText = styled.span`
  margin-right: 0.2rem;
`

export const SignUpLink = styled.span`
  color: ${({ theme }) => `${theme.colors.orange[600]}`};
  margin-right: 0.2rem;
  cursor: pointer;

  :hover {
    text-decoration: underline;
  }
`

export const SignInLink = styled(SignUpLink)`
  margin-bottom: 1.25rem;

  ${mediaQueryUp('m')} {
    margin-bottom: unset;
  }
`

export const StyledInput = styled(Input)`
  font-weight: ${({ theme }) => theme.text.fontWeight.medium};
  font-size: ${({ theme }) => theme.text.fontSize.regular};
  line-height: ${({ theme }) => theme.text.lineHeight.loose};
  letter-spacing: ${({ theme }) => theme.text.letterSpacing.normal};
`

export const FieldsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 1.5rem;

  ${mediaQueryUp('m')} {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-row-gap: 1.5rem;
    grid-column-gap: 1.5rem;

    * {
      grid-column: span 2;
    }

    > label:nth-child(1) {
      grid-column: span 1;
    }

    > label:nth-child(2) {
      grid-column: span 1;
    }

    > label:nth-child(6) {
      margin-top: 0.25rem;
    }

    ${Container} {
      margin-top: 0.25rem;
    }
  }
`

export const StyledCheckbox = styled(Checkbox)`
  font-weight: ${({ theme }) => theme.text.fontWeight.medium};
`

export const StyledAnchor = styled.a`
  color: ${({ theme }) => theme.colors.orange[500]};
  &:hover {
    color: ${({ theme }) => theme.colors.orange[600]};
  }
`

export const ConsentNotices = styled.div`
  margin: 1.5rem 0;

  ${Body2} {
    margin-top: 1rem;
    color: ${({ theme }) => theme.colors.gray[800]};
  }
`

export const SavedContainer = styled.div`
  display: flex;
  flex-direction: column;
`

export const PersonIcon = styled(Icon)`
  width: 1.25rem;
  height: 1.25rem;
  filter: ${({ theme }) => theme.filters.gray[700]};
  margin-right: 0.75rem;
`

export const PersonInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
`

export const PersonInfo = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 0.75rem;
`

export const RestaurantCommentContainer = styled.div`
  display: flex;
  margin-top: 1.5rem;
`

export const RestaurantCommentInfo = styled(Heading5)`
  font-weight: ${({ theme }) => theme.text.fontWeight.semiBold};
  font-size: ${({ theme }) => theme.text.fontSize.m};
  margin-right: 0.2rem;

  ${mediaQueryUp('m')} {
    font-size: ${({ theme }) => theme.text.fontSize.l};
  }
`

export const RestaurantComment = styled(Heading5)`
  font-size: ${({ theme }) => theme.text.fontSize.m};

  ${mediaQueryUp('m')} {
    font-size: ${({ theme }) => theme.text.fontSize.l};
  }
`

export const Wrapper = styled.div`
  max-width: 848px;
  width: 100%;
`

export const ToastContainer = styled.div`
  width: 100%;

  ${StyledToast}:last-of-type {
    margin-bottom: 1.5rem;
  }
`

export const PasswordCollapse = styled.div`
  border-top: 1px solid ${({ theme }) => theme.colors.gray[200]};
  border-bottom: 1px solid ${({ theme }) => theme.colors.gray[200]};
  .password-collapse > div {
    width: 100%;
  }

  ${InputWrapper} {
    padding-bottom: 24px;
  }

  ${Header} {
    flex-direction: row-reverse;
    justify-content: flex-end;
    gap: 4px;
    padding-left: 0;
    text-decoration: underline;

    ${mediaQueryDown('l')} {
      font-weight: 300;
    }
  }
`

export const Info = styled(Icon)`
  filter: ${({ theme }) => theme.filters.gray[600]};
  height: 1rem;
  margin-left: 0.5rem;
`
