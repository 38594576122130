import { css } from '@emotion/react'
import styled from '@emotion/styled'

import { mediaQueryUp } from '~/theme/utils/grid'

import { imageUrl } from './FestivalMenuBannerTypes'

export const Banner = styled.div<{ color?: string; image?: imageUrl }>`
  display: flex;
  width: 100%;
  height: 9rem;
  margin-bottom: 1.5rem;
  border-radius: 0.5625rem;

  ${({ image }) =>
    image &&
    css`
      background: url('${image.mobile}') no-repeat 0 0 / contain;
    `}
  ${({ color }) =>
    color &&
    css`
      background-color: ${color};
    `}
  align-items: center;

  ${mediaQueryUp('m')} {
    height: 7.5rem;
    ${({ image }) =>
      image &&
      css`
        background-image: url('${image.desktop}');
      `}
  }
`

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: auto;
  margin-right: 0.5rem;

  width: 100%;
  max-width: 150px;

  ${mediaQueryUp('s')} {
    max-width: 200px;
  }

  ${mediaQueryUp('m')} {
    width: 100%;
    max-width: 100%;
    margin-left: 15rem;
    margin-right: 1rem;
    flex-direction: row;
  }
`

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
`

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-right: auto;
  margin-top: 0.625rem;

  a {
    padding: 0;
    min-height: 1.875rem;
    font-size: ${({ theme }) => theme.text.fontSize.xs};
  }

  ${mediaQueryUp('m')} {
    align-items: center;
    margin: 0 0 0 auto;

    a {
      padding: 0.75rem 1.5rem;
      min-height: 2.875rem;
      font-size: ${({ theme }) => theme.text.fontSize.base};
    }
  }
`

export const MainText = styled.span<{ color?: string }>`
  font-size: 1.25rem;
  font-weight: 500;

  ${mediaQueryUp('m')} {
    font-size: 1.625rem;
  }

  ${({ color }) =>
    color &&
    css`
      color: ${color};
    `}
`

export const SecondaryText = styled.span`
  font-size: ${({ theme }) => theme.text.fontSize.xs};
  font-weight: 400;
  ${({ color }) =>
    color &&
    css`
      color: ${color};
    `}

  ${mediaQueryUp('m')} {
    font-size: ${({ theme }) => theme.text.fontSize.s};
  }

  ${mediaQueryUp('l')} {
    font-size: ${({ theme }) => theme.text.fontSize.m};
  }
`
