// @ts-strict-ignore

import { get, isEmpty } from 'lodash'
import { useRouter } from 'next/router'

import { ApolloError } from '@apollo/client'

import { getTimeLeft } from '~/utils/datetime'
import { formatErrorMessages } from '~/utils/formatErrorMessages'
import { isFER } from '~/utils/isReservableType'

import { AdditionalConsent } from '~/components/Checkout/CheckoutPage/CheckoutGuestLogin/CheckoutGuestLoginTypes'
import { getMetadataValue } from '~/components/GiftEvents/pages/GiftEventsListingPage/GiftEventsListingPageUtils'
import { smallModalProps } from '~/components/User/LoginModal/LoginModalUtils'
import { ConsentLabels } from '~/components/User/UserPageLayout/UserSettings/UserSettingsUtils'
import { getClubConfig } from '~/config/clubConfig'
import { paths } from '~/constants'
import { FestivalCodesEnum, OrderStatusEnum, PriceValues, ReservableEnum, UserConsentSubtypeEnum } from '~/generated/graphql'
import { gt } from '~/locale'
import { NewToastParams } from '~/shared/atoms/Toast/ToastTypes'
import { Timestamp } from '~/types/common'

import { hasComplimentaryCrossSells, hasCrossSells } from './CheckoutCrossSells/CheckoutCrossSellsUtils'
import {
  AvailableCrossSells,
  CheckoutFlow,
  CheckoutReservable,
  CheckoutReservation,
  CheckoutStepsEnum,
  MinimumReservable,
  PaymentMethod,
} from './CheckoutPageTypes'

export const calculateCrossSellsPrice = reservationCrossSells => {
  if (!reservationCrossSells?.length) return 0

  const totalPrice = reservationCrossSells.reduce((acc, { quantity, crossSell }) => {
    return acc + quantity * crossSell.price
  }, 0)

  return totalPrice
}

export const calculatePriceToPay = (
  crossSellsPrice: number,
  extrasPrice: number,
  priceValues: Pick<PriceValues, 'basePrice' | 'feeablePeopleCount' | 'reservationFeeUnitPrice'>
) => {
  const { basePrice, feeablePeopleCount, reservationFeeUnitPrice } = priceValues
  return crossSellsPrice + extrasPrice + basePrice + reservationFeeUnitPrice * feeablePeopleCount
}

export const getAvailableExtras = extras => {
  return extras?.filter(({ enabled }) => enabled)
}

export const handleError = (err: ApolloError, add: (p: NewToastParams) => void) => {
  formatErrorMessages(err)?.map(error =>
    add({
      type: 'error',
      containerSelector: '#ReservationSectionToastsContainer',
      size: 'large',
      message: error,
    })
  )
}

export const updateResponseKey = (reservableType: ReservableEnum) => {
  switch (reservableType) {
    case ReservableEnum.Daily:
      return 'updateDailyReservation'
    case ReservableEnum.Event:
      return 'updateEventReservation'
    default:
      return 'updateFestivalReservation'
  }
}

export const getReservationExpirationTimeLeft = ({ timestamp, expiresAt }: { timestamp: Timestamp; expiresAt: Timestamp }) => {
  return getTimeLeft(timestamp, expiresAt)
}

interface GenerateSteps {
  reservable: CheckoutReservable
  inEditMode: boolean
  status: OrderStatusEnum
  reservableExtrasAvailable?: boolean
}

export const generateSteps = ({ reservable, inEditMode, status, reservableExtrasAvailable }: GenerateSteps): CheckoutStepsEnum[] => {
  const availableCrossSells: AvailableCrossSells = get(reservable, 'crossSells')
  const availableMenus = get(reservable, 'menus')?.length
  const hasAddition = {
    reservable: isFER(reservable) || reservableExtrasAvailable || availableMenus,
    crossSells: !isEmpty(availableCrossSells),
  }

  return [
    { name: CheckoutStepsEnum.Additions, isApplicable: hasAddition.reservable || hasAddition.crossSells },
    { name: CheckoutStepsEnum.Guest, isApplicable: !inEditMode },
    { name: CheckoutStepsEnum.Payment, isApplicable: status !== OrderStatusEnum.Succeeded },
  ]
    .filter(({ isApplicable }) => isApplicable)
    .map(({ name }) => name)
}

export const nextStepLabels = {
  toGuestDetails: gt.tp('CheckoutPage', 'Guest Details'),
  toPayment: gt.tp('CheckoutPage', 'Confirm and proceed to payment options'),
  bookNow: gt.tp('CheckoutPage', 'Book your table'),
  saveChanges: gt.tp('CheckoutPage', 'Save changes'),
  noChangesWereMade: gt.tp('CheckoutPage', 'No changes were made'),
}

export const dateTimeGuestsModalProps = {
  ...smallModalProps,
  closeIconPosition: 'outside' as const,
}

export const hasReservableExtraAssigments = (reservation: Pick<CheckoutReservation, 'reservableExtraAssignments'>) =>
  Array.isArray(reservation.reservableExtraAssignments) && reservation.reservableExtraAssignments.length > 0

export const additionalPartnerConsentsForFestival: Partial<Record<FestivalCodesEnum, AdditionalConsent[]>> = {
  gw: [
    {
      subType: UserConsentSubtypeEnum.Pernod,
      required: false,
      label: <ConsentLabels path='partners.gw.label' />,
      showMore: <ConsentLabels path='partners.gw.showMore' />,
    },
  ],
}

export const convertPermittedPaymentMethods = (permittedPaymentMethods: string[] = ['CARD', 'BLIK', 'PAYPO', 'PBL']): PaymentMethod[] => {
  const clubConfig = getClubConfig()

  const paymentMethodConfig: { [key in PaymentMethod]?: boolean } = {
    [PaymentMethod.BLIK]: clubConfig.blikEnabled,
    [PaymentMethod.Card]: clubConfig.directCardPaymentEnabled,
    [PaymentMethod.Klarna]: clubConfig.klarnaEnabled,
    [PaymentMethod.PayPo]: clubConfig.paypoEnabled,
    [PaymentMethod.VisaMobile]: clubConfig.visaMobileEnabled,
  }

  let convertedMethods = permittedPaymentMethods.flatMap(method => {
    switch (method) {
      case 'INTERNAL':
        return PaymentMethod.Card
      case 'PAYU':
        return [PaymentMethod.PBL, PaymentMethod.BLIK, PaymentMethod.VisaMobile, PaymentMethod.ApplePay, PaymentMethod.GooglePay]
      default:
        return method as PaymentMethod
    }
  })

  convertedMethods = convertedMethods.filter(method => paymentMethodConfig[method] !== false || paymentMethodConfig[method] === undefined)

  return convertedMethods
}

export const shouldShowVisaVoucherBanner = reservable => {
  const isFERReservation = isFER(reservable)

  if (isFERReservation) {
    return getMetadataValue(reservable.festivalEdition.festival.metadatas, 'enableVisa') === 'true'
  }

  return false
}

type GetSubmitButtonLabelProps = {
  inEditMode: boolean
  isPaymentStep: boolean
  wasTouched: boolean
}

export const getSubmitButtonLabel = ({ inEditMode, isPaymentStep, wasTouched }: GetSubmitButtonLabelProps) => {
  if (inEditMode) {
    if (!wasTouched) {
      return nextStepLabels.noChangesWereMade
    }
    if (isPaymentStep) {
      return nextStepLabels.toPayment
    }
    return nextStepLabels.saveChanges
  }

  return nextStepLabels.toGuestDetails
}

export const getCheckoutSecondStep = ({
  reservable,
  code,
  flow,
  editMode,
  isCHM,
}: {
  reservable: MinimumReservable
  code: CheckoutReservation['code']
  flow?: CheckoutFlow
  editMode?: boolean
  hasAnyCrossSells?: boolean
  isCHM?: boolean
}) => {
  const hasCrossSellsType = 'crossSells' in reservable

  if (hasCrossSellsType && hasComplimentaryCrossSells(reservable.crossSells) && !isCHM) {
    return paths.checkoutComplimentaryCrossSells(code, flow, editMode)
  }

  if (hasCrossSellsType && hasCrossSells(reservable.crossSells)) {
    return paths.checkoutCrossSells(code, flow, editMode)
  }

  return paths.checkoutGuest(code, flow, editMode)
}

export const useCheckoutFlow = () => {
  const router = useRouter()
  const flow = router.query.flow as Partial<CheckoutFlow>
  const editMode = !!router.query.editMode

  return { flow, editMode }
}
