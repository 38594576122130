import theme from '~/theme'

import { Styles } from './FestivalMenuBannerTypes'

export const buildMenusRoute = (festivalEditionId: number, location: string) => {
  return `/menus?festivalFilter=${festivalEditionId}&location=${location}`
}

export const fdwStyle: Styles = {
  bgColor: '#241E49',
  buttonColor: 'fdw',
  titleFontColor: '#FFFFFF',
  fontColor: '#FFFFFF',
  imageUrl: {
    desktop: '/assets/festival-menu-banner-fdw.png',
    mobile: '/assets/festival-menu-banner-fdw-mobile.png',
  },
}

export const defaultStyle: Styles = {
  bgColor: theme.colors.orange[50],
  titleFontColor: theme.colors.gray[1000],
  buttonColor: 'orange',
  fontColor: theme.colors.gray[800],
  imageUrl: {
    desktop: '/assets/festival-menu-banner.png',
    mobile: '/assets/festival-menu-banner-mobile.png',
  },
}
