import React, { useMemo, useState } from 'react'
import { useMediaQuery } from 'react-responsive'

import { useRouter } from 'next/router'
import url from 'url'

import { mediaQueryIsMobile } from '~/theme/utils/grid'
import { Heading5, Heading6 } from '~/theme/utils/typography'

import CardActions from '~/components/Product/ProductCardActions'
import { paths } from '~/constants'
import TagBar from '~/shared/molecules/TagBar'

import { RestaurantCardProps } from '..'
import { MainContentContainer, RestaurantDescription, RestaurantName, TagContainer } from '../CardShards'
import { ImageSection } from '../components'

import { Wrapper } from './RestaurantCardShards'

export const RestaurantCardView = ({
  id: ferId,
  restaurant,
  subtitle,
  type = 'default',
  withCarousel = false,
  menuTooltip,
  onClick,
  ...props
}: RestaurantCardProps) => {
  const router = useRouter()

  const [isHovered, setIsHovered] = useState(false)

  const isMobile = useMediaQuery({ query: mediaQueryIsMobile })

  const { imageFiles, externalSaleUrl } = props

  const { id: restaurantId, name, slug, description, favorited } = restaurant

  const cardLink = useMemo(() => {
    if (type === 'FER') {
      return { query: { ...router.query, ferId } } as url.UrlObject
    }

    return (
      externalSaleUrl ||
      ({
        pathname: paths.singleRestaurant({ slug }),
        query: router.query,
      } as url.UrlObject)
    )
  }, [externalSaleUrl, router.query, slug, type, ferId])

  return (
    <Wrapper withBorder onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)} onClick={onClick}>
      <CardActions href={cardLink}>
        <MainContentContainer cardType={type}>
          <ImageSection
            type={type}
            restaurantId={restaurantId}
            favorited={favorited}
            withCarousel={withCarousel}
            isHovered={isHovered}
            restaurantName={restaurant.name}
            sliderImages={imageFiles || []}
          />
          <RestaurantName as={isMobile ? Heading6 : Heading5} fontWeight='semiBold'>
            {name}
          </RestaurantName>
          {menuTooltip}
          <RestaurantDescription fontWeight='regular'>{subtitle ?? description}</RestaurantDescription>
          <TagContainer>
            <TagBar variant={'plainText'} sections={['price']} maxBaseTagCount={5} size={'small'} restaurant={restaurant} />
          </TagContainer>
        </MainContentContainer>
      </CardActions>
    </Wrapper>
  )
}
