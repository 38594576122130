import { useMediaQuery } from 'react-responsive'

import { mediaQueryIsTablet } from '~/theme/utils/grid'

import { paths } from '~/constants'
import { gt } from '~/locale'
import { Container, Separator } from '~/shared/molecules/Header/TopBarMenu/TopBarMenuShards'
import { TopBarLinkItem } from '~/shared/molecules/Header/TopBarMenu/TopBarMenuView'
import LanguageSwitcher from '~/shared/molecules/LanguageSwitcher'

type FestivalListingHeaderTopBarProps = {
  onClose(): void
}

export const FestivalListingHeaderTopBar = ({ onClose }: FestivalListingHeaderTopBarProps) => {
  const isTablet = useMediaQuery({ query: mediaQueryIsTablet })
  const { tp } = gt

  return (
    <Container isTablet={isTablet}>
      {isTablet && (
        <>
          <TopBarLinkItem
            href={paths.festivalListing('restaurantweek')}
            label={tp('FERListingPage', 'Reserve')}
            isTablet={isTablet}
            onClick={onClose}
            enabledForFeatureFlag='aboutPageEnabled'
          />
          <TopBarLinkItem
            href={paths.sideEventsListing}
            label={tp('FERListingPage', 'Premium Experiences')}
            isTablet={isTablet}
            onClick={onClose}
            enabledForFeatureFlag='aboutPageEnabled'
          />
          <TopBarLinkItem
            href={paths.festivalListing('breakfastweek')}
            label={tp('FERListingPage', 'Other Festivals')}
            isTablet={isTablet}
            onClick={onClose}
            enabledForFeatureFlag='aboutPageEnabled'
          />
          <TopBarLinkItem href={paths.blog} label={tp('Header', 'Blog')} isTablet={isTablet} onClick={onClose} />
        </>
      )}
      <TopBarLinkItem
        href={paths.about}
        label={tp('Header', 'About us')}
        isTablet={isTablet}
        onClick={onClose}
        enabledForFeatureFlag='aboutPageEnabled'
      />
      <TopBarLinkItem
        href={paths.career}
        label={tp('Header', 'Career')}
        isTablet={isTablet}
        onClick={onClose}
        enabledForFeatureFlag='careerPageEnabled'
      />
      {/* <TopBarLinkItem href={paths.giftEventsListing} label={tp('HomePage', 'As a gift')} isTablet={isTablet} onClick={onClose} /> */}
      {/* <TopBarLinkItem href={paths.partners} label={tp('Header', 'Partners')}
        isTablet={isTablet} onClick={onClose} /> */}
      {!isTablet && <div className='separator' style={{ flexGrow: 1 }} />}
      {/* <TopBarLinkItem href={paths.forRestaurateurs} label={tp('Header', 'For Restaurateurs')} isTablet={isTablet} onClick={onClose} /> */}
      <TopBarLinkItem href={paths.faq} label={tp('Header', 'Help')} isTablet={isTablet} onClick={onClose} />
      {isTablet && <Separator />}
      <LanguageSwitcher variant={isTablet ? 'big' : 'small'} onClose={onClose} />
    </Container>
  )
}
