import get from 'lodash/get'

import { StyledAnchor } from '~/components/Checkout/CheckoutPage/CheckoutGuestLogin/CheckoutGuestLoginShards'
import { gt } from '~/locale'

export const ConsentLabels = ({ path }: { path: string }) => {
  const labels = {
    partners: {
      gw: {
        label: gt.tp(
          'CheckoutPage',
          'I agree that Wyborowa S.A. with its registered seat in Poznań (61-070) at 23 Janikowska Street ("Wyborowa") may send commercial information to the contact data provided by me and use this data for direct marketing and promotion of the Beefeater brand, i.e. via e-mail, online social networks and to the telephone number provided (SMS, telephone contact).'
        ),
        showMore: (
          <span>
            {gt.tp(
              'CheckoutPage',
              'Please be informed that your personal data will be processed by the administrator - Wyborowa - and may be made available to other entities from the '
            )}{' '}
            <StyledAnchor href='https://wyborowa.pl/wp-content/uploads/2024/03/Grupa-Pernod-Ricard-lista-30-06-2023-r.pdf' target='_blank'>
              {gt.tp('CheckoutPage', 'Pernod Ricard Group')}
            </StyledAnchor>{' '}
            {gt.tp(
              'CheckoutPage',
              'The basis for the processing of your personal data is the legitimate interest of Wyborowa or a third party in conducting marketing activities (Article 6(1)(f) RODO). The data provided will be processed for the purpose of marketing the services and products (alcoholic beverages) and events of Wyborowa and entities of the'
            )}{' '}
            <StyledAnchor href='https://wyborowa.pl/wp-content/uploads/2024/03/Grupa-Pernod-Ricard-lista-30-06-2023-r.pdf' target='_blank'>
              {gt.tp('CheckoutPage', 'Pernod Ricard Group')}
            </StyledAnchor>{' '}
            {gt.tp(
              'CheckoutPage',
              ',including sending marketing communications. Please be informed that you have the right to request access to your personal data, rectification, deletion, restriction of processing and data portability, as well as to object to the processing of your personal data. To do so, please contact us at e-mail address: regulamin@wyborowa.com. You also have the right to file a complaint with the supervisory authority - the President of the Office for Personal Data Protection. For more information about the processing of your personal data, please see'
            )}{' '}
            <StyledAnchor href='https://wyborowa.pl/polityka-prywatnosci' target='_blank'>
              {gt.tp('CheckoutPage', 'the Privacy Policy')}
            </StyledAnchor>
            .
          </span>
        ),
      },
    },
    rodoAgreement: {
      label: gt.tp('UserSettings', 'I consent to the processing of my personal data'),
      showMore: gt.tp(
        'UserSettings',
        'indicated in the order (name, surname, e-mail address, telephone) by Restaurant Club sp. z o.o., including their transfer to the selected Restaurant for the purpose of performing the agreement, to the extent necessary for the performance of the Service and the conclusion of the Agreement (art. 6(1)(b) RODO). I declare that the data provided are true, being aware that their provision is voluntary and that the consequence of failing to provide personal data is the inability to carry out the Order, provide the Service and make the Reservation.'
      ),
    },
  }

  return <>{get(labels, path)}</>
}
