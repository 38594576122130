import { useCallback } from 'react'

import { useRouter } from 'next/router'

import { Country } from '~/utils/countries'

import { getClubConfig, isLocaleEnabled } from '~/config/clubConfig'
import { gt } from '~/locale'
import { loadLocales } from '~/locale/loader'
import { DropdownItem } from '~/shared/atoms/Dropdown'
import { Flag } from '~/shared/atoms/Flag/Flag'
import { LocaleItemContainer } from '~/shared/molecules/LanguageSwitcher/LanguageSwitcherShards'

interface UseLanguageSwitcherOutput {
  languages: string[]
  options: DropdownItem[]
  onLanguageChange(lang: string): void
}

export const localeToCountry: Record<string, Country> = {
  en: Country.UnitedKingdom,
  pl: Country.Poland,
  uk: Country.Ukraine,
  cs: Country.CzechRepublic,
  de: Country.Germany,
}

const clubConfig = getClubConfig()

export const LocaleItem = ({ locale }: { locale: string }) => {
  return (
    <LocaleItemContainer>
      <Flag country={localeToCountry[locale]}></Flag>
      {gt.tp('Languages', clubConfig.enabledLanguages[locale])}
    </LocaleItemContainer>
  )
}

const useLanguageSwitcher = (): UseLanguageSwitcherOutput => {
  const router = useRouter()
  const onLanguageChange = useCallback(
    (value: string | false) => {
      const { pathname, asPath, query } = router
      loadLocales(value).then(() => {
        router.push({ pathname, query }, asPath, { locale: value, shallow: true })
      })
    },
    [router]
  )

  const languages = router.locales?.filter(isLocaleEnabled) || []
  const options = languages.map(language => ({
    key: language,
    label: gt.tp('Languages', language.toUpperCase()),
    icon: <Flag country={localeToCountry[language]} />,
  }))

  return {
    languages,
    options,
    onLanguageChange,
  }
}

export default useLanguageSwitcher
