// @ts-strict-ignore
import React, { useState } from 'react'

import dayjs from 'dayjs'
import { useRouter } from 'next/router'

import { deleteCookie, getCookieAsParsedJson } from '~/utils/cookie'
import { formatErrorMessages, getErrorCodes } from '~/utils/formatErrorMessages'
import { dayjsToString, formatPrice, getPossibleFERSlots, getSlotFromTime } from '~/utils/graphqlDataFormatters'
import { runEcommerceAdd } from '~/utils/gtag'
import { hasWindow } from '~/utils/helpers'
import useReservationCreate from '~/utils/hooks/useReservationCreate'

import { ChefsMenuSelectHeader } from '~/components/Checkout/CheckoutPage/ChefsMenuDetailsModal'
import MarketingConsentModal from '~/components/Checkout/CheckoutPage/MarketingConsentModal'
import { modalProps } from '~/components/Checkout/CheckoutPage/MarketingConsentModal/MarketingConsentModalUtils'
import { HeaderWrapper } from '~/components/ChefsMenu/ChefsMenuPage/ChefsMenuPageShards'
import { getRangeStart } from '~/components/Listing/pages/FERListingPage/FERListingPageUtils'
import SlotSelectionPanel from '~/components/Reservation/SlotSelectionPanel'
import { getDefaultFestivalMenuCounts } from '~/components/Reservation/SlotSelectionPanel/SlotSelectionPanelUtils'
import { StyledToastContainer } from '~/components/User/LoginModal/LoginModalShards'
import { paths, RESERVABLE_TYPES } from '~/constants'
import { errors as errorMessages, shouldTriggerMarketingModal } from '~/constants/errors'
import {
  FerModalQuery,
  FestivalCodesEnum,
  LeanMenuFragment,
  ReservableEnum,
  useCreateFestivalReservationMutation,
} from '~/generated/graphql'
import { gt } from '~/locale'
import { useToast } from '~/shared/atoms/Toast'
import { useModal } from '~/shared/molecules/Modal'

import { ALL_DAY_SLOT } from '../FERModalUtils'

interface FERModalSlotSelectProps {
  price: number
  fer: FerModalQuery['festivalEditionRestaurant']
  menus: LeanMenuFragment[]
}

export function FERModalSlotSelect({ price, fer, menus }: FERModalSlotSelectProps) {
  const router = useRouter()

  const toast = useToast()

  const modal = useModal()

  const [createFestivalReservation] = useReservationCreate({
    useCreateReservationMutation: useCreateFestivalReservationMutation,
  })

  const [presaleCode, setPresaleCode] = useState('')

  const { id: reservableId, restaurant, festivalEdition } = fer || {}

  const { startsAt, endsAt, reservableFrom, reservationFeeUnitPrice } = festivalEdition || {}

  const feState = festivalEdition?.state
  const rangeStart = getRangeStart(dayjs.parseZone(startsAt), dayjs.parseZone(reservableFrom))
  const rangeEnd = dayjs.parseZone(endsAt)

  const earlyReservation = getCookieAsParsedJson(undefined, 'earlyReservationVoucher')
  const earliestReservableFrom = dayjs.parseZone(reservableFrom)
  const showPresale = feState === 'PRESALE' && (!earlyReservation || earlyReservation.festivalId !== festivalEdition?.id)

  return (
    <SlotSelectionPanel
      header={
        <>
          <HeaderWrapper>
            <ChefsMenuSelectHeader
              price={price}
              additionalPriceInfo={
                reservationFeeUnitPrice
                  ? gt.tp('FestivalModal', '(+ service fee {{ price }})', {
                      price: formatPrice(reservationFeeUnitPrice),
                    })
                  : undefined
              }
            />
          </HeaderWrapper>
          <StyledToastContainer id={'FERToastContainer'} />
        </>
      }
      fieldsToHide={['title', 'submit']}
      type={RESERVABLE_TYPES.FER}
      scrollTarget={hasWindow() ? document.getElementById('dialog:ferDialog:content') ?? undefined : undefined}
      price={price}
      reservableInput={{ id: reservableId, type: ReservableEnum.FestivalEditionRestaurant }}
      possibleSlots={fer && (fer.allDaySlots ? [ALL_DAY_SLOT] : getPossibleFERSlots(fer))}
      allDaySlots={fer.allDaySlots}
      dateRange={{ startsOn: dayjsToString(rangeStart), endsOn: dayjsToString(rangeEnd) }}
      prevParams={undefined}
      defaultDate={earliestReservableFrom}
      defaultSlot={getSlotFromTime(earliestReservableFrom)}
      forceSearchParams={true}
      showPresale={showPresale}
      onPresaleChange={e => setPresaleCode(e.target.value || '')}
      onSubmit={async params => {
        const additionalVariables = showPresale
          ? { voucherCode: presaleCode }
          : earlyReservation?.festivalId === festivalEdition?.id
          ? { voucherCode: earlyReservation?.code }
          : {}

        await createFestivalReservation({
          variables: {
            ...params,
            date: dayjsToString(params.date),
            festivalEditionId: festivalEdition.id,
            restaurantId: restaurant.id,
            menus: getDefaultFestivalMenuCounts(menus),
            ...additionalVariables,
          },
          onCompleted: data => {
            router.push(paths.checkoutFestivalMenu(data.createFestivalReservation.code))
            modal.close()

            if (Object.keys(additionalVariables).length > 0) {
              deleteCookie(null, 'earlyReservationVoucher')
            }
          },
          onError: error => {
            let errors = formatErrorMessages(error)
            const errorCodes = getErrorCodes(error)
            if (shouldTriggerMarketingModal(errorCodes)) {
              modal.open(modalProps, <MarketingConsentModal handleAccepted={modal.close} />)
            }

            if (errorCodes.includes('discount_not_found') && showPresale && presaleCode === '') {
              errors = [gt.tp('Error', errorMessages.no_presale_code)]
            }

            errors.map(err =>
              toast.add({
                message: err,
                type: 'error',
                containerSelector: '#FERToastContainer',
                size: 'small',
              })
            )
          },
        })

        runEcommerceAdd({
          reservableData: {
            restaurant,
            festivalEdition,
            price,
            quantity: params.peopleCount,
          },
          reservableType: RESERVABLE_TYPES.FER,
        })
      }}
      minimumPeopleCount={festivalEdition.minPeopleCount}
      maxPeopleCount={festivalEdition.maxPeopleCount}
      showExtraGuest={festivalEdition.festival.code !== FestivalCodesEnum.Gw}
    />
  )
}
