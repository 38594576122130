import styled from '@emotion/styled'

import { mediaQueryUp } from '~/theme/utils/grid'
import { Body2 } from '~/theme/utils/typography'

import Button from '~/shared/atoms/Button'
import Icon from '~/shared/atoms/Icon'

export const AwardsContainer = styled.div`
  padding: 1rem 0;
  border-bottom: 1px solid ${({ theme }) => theme.colors.gray[100]};
`

export const FooterContainer = styled.div``

export const Wrapper = styled.footer`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 2rem 0;

  ${mediaQueryUp('l')} {
    padding: 2.5rem 0;
    display: grid;
    grid-template-areas:
      'logo logo'
      'links join'
      'partners partners'
      'copy socials';
  }

  @media (min-width: 1200px) {
    padding: 3.5rem 0;
  }
`

export const Logo = styled.img`
  grid-area: logo;
  display: block;
  height: 1.5rem;
  width: auto;
  margin-bottom: 2rem;

  ${mediaQueryUp('m')} {
    margin-bottom: 3rem;
  }

  ${mediaQueryUp('l')} {
    height: 2rem;
    margin-bottom: 4.25rem;
  }
`

export const LinkMap = styled.div`
  width: 100%;
  grid-area: links;

  & > div > div:first-of-type {
    padding: 1.25rem 0;
  }

  h6 {
    text-transform: uppercase;
    font-size: ${({ theme }) => theme.text.fontSize.s};
  }

  ${mediaQueryUp('m')} {
    display: flex;
    width: 100%;

    & > div {
      width: 100%;
    }
    & h6 {
      text-transform: unset;
      font-size: unset;
      width: 100%;
      min-width: 100%;
      margin-bottom: 1.5rem;
    }
  }

  @media (min-width: 1100px) {
    max-width: unset;
    display: flex;

    & h6 {
      min-width: 215px;
    }
  }
`

export const LinkItem = styled.a`
  width: 100%;
  display: block;
  padding-bottom: 0.75rem;
  font-weight: ${({ theme }) => theme.text.fontWeight.medium};
  cursor: pointer;
`

export const JoinTheClub = styled.div`
  margin: 3rem 0;
  grid-area: join;
  max-width: 400px;

  h6 {
    margin-top: 0.75rem;
    margin-bottom: 2rem;
    color: ${({ theme }) => theme.colors.gray[600]};
  }

  ${mediaQueryUp('l')} {
    max-width: unset;
    width: 400px;
    justify-self: flex-end;
    margin-top: 0;
  }
`

export const JoinInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 0.5rem;
  gap: 0.5rem;

  ${mediaQueryUp('m')} {
    flex-direction: row;
  }
`

export const JoinButton = styled(Button)`
  ${mediaQueryUp('m')} {
    width: 6.625rem;
  }
`

export const JoinHeading = styled.div`
  font-weight: ${({ theme }) => theme.text.fontWeight.medium};

  ${mediaQueryUp('m')} {
    margin-bottom: 1.5rem;
  }
`

export const ToastContainer = styled.div`
  margin-top: -0.5rem;
`

export const Consent = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
  gap: 1rem;
  font-size: ${({ theme }) => theme.text.fontSize.xxs};
  line-height: ${({ theme }) => theme.text.lineHeight.loose};
  letter-spacing: ${({ theme }) => theme.text.letterSpacing.wide};
  max-width: 400px;

  & a {
    color: ${({ theme }) => theme.colors.orange[500]};
  }
`

export const SocialMedia = styled.div`
  grid-area: socials;
  display: flex;
  gap: 1.75rem;
  align-items: center;
  margin-top: 2rem;

  ${mediaQueryUp('l')} {
    justify-self: flex-end;
    margin: 0;
  }
`

export const IconButton = styled(Icon)`
  width: 1.25rem;
  filter: ${({ theme }) => theme.filters.gray[1000]};
  cursor: pointer;
`

export const Partners = styled.div`
  grid-area: partners;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1.75rem;
  margin-top: 0.5rem;
  border-bottom: 3px solid ${({ theme }) => theme.colors.gray[100]};
  padding-bottom: 2.5rem;

  ${mediaQueryUp('l')} {
    border-top: 3px solid ${({ theme }) => theme.colors.gray[100]};
    flex-direction: row;
    padding: 3rem 0;
    margin-bottom: 2rem;
    gap: 3.25rem;
  }
`

export const FooterPartner = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.625rem;
  align-items: flex-start;

  ${mediaQueryUp('l')} {
    align-items: center;
  }

  & p {
    font-size: ${({ theme }) => theme.text.fontSize.xxs};
    line-height: ${({ theme }) => theme.text.lineHeight.none};
    letter-spacing: ${({ theme }) => theme.text.letterSpacing.wider};
  }
`

export const BottomLinks = styled.div<{ addPadding: boolean }>`
  margin-top: 2rem;
  margin-bottom: ${({ addPadding }) => (addPadding ? '4rem' : 0)};
`

export const Copyright = styled(Body2)`
  color: ${({ theme }) => theme.colors.gray[800]};
  line-height: ${({ theme }) => theme.text.lineHeight.normal};

  ${mediaQueryUp('m')} {
    margin-top: 2rem;
  }

  ${mediaQueryUp('l')} {
    margin-top: 0;
  }
`

export const Benefits = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`

export const BenefitsTitle = styled.li`
  font-size: ${({ theme }) => theme.text.fontSize.m};
`

export const Benefit = styled.li`
  font-size: ${({ theme }) => theme.text.fontSize.s};
`

export const BenefitIcon = styled(Icon)`
  width: 0.75rem;
  margin-right: 0.25rem;
  filter: ${({ theme }) => theme.filters.gray[1000]};
`

export const Address = styled.div`
  display: flex;
  flex-direction: column;
`

export const AddressInfo = styled.span`
  font-size: ${({ theme }) => theme.text.fontSize.s};
`
