// @ts-strict-ignore
import React from 'react'

import { Heading6 } from '~/theme/utils/typography'
import { formatMenuLabel } from '~/utils/graphqlDataFormatters'

import Menu from '~/components/Checkout/CheckoutPage/shared/Menu'
import ProductDetailsPanel from '~/components/Product/ProductDetailsPanel'
import { RestaurantDetails } from '~/components/Product/ProductDetailsPanel/ProductDetailsPanelTypes'
import { FerModalQuery, LeanMenuFragment } from '~/generated/graphql'
import { gt } from '~/locale'

import { MenuLabel, MenuPreface, Menus, PartnersBox } from '../FERModalShards'

interface FERModalMenusProps {
  restaurant: RestaurantDetails
  festivalEdition: FerModalQuery['festivalEditionRestaurant']['festivalEdition']
  menus: LeanMenuFragment[]
}

const fromPartners = {
  bw: (
    <>
      <Heading6 fontWeight='medium'>{gt.tp('PartnerMessage', 'Alprocino or Alprolatte.')}</Heading6>
      <Heading6 fontWeight='medium'>{gt.tp('PartnerMessage', 'Enjoy a free cup of coffee from Alpro!')}</Heading6>
    </>
  ),
}

const menuPreface = {
  fdw: <MenuPreface fontWeight='semiBold'>{gt.tp('Menu', 'AMUSE-BOUCHE! As a welcome gift, a surprise for the palate')}</MenuPreface>,
}

export function FERModalMenus({ festivalEdition, menus, restaurant }: FERModalMenusProps) {
  const festivalCode = festivalEdition.festival.code
  const forceLabels = festivalEdition.forceLabels

  const contentFromPartners = fromPartners[festivalCode]
  const festivalMenuPreface = menuPreface[festivalCode]

  return (
    <Menus>
      {contentFromPartners && <PartnersBox>{contentFromPartners}</PartnersBox>}
      {menus.map((menu, i) => (
        <div key={menu.id}>
          <MenuLabel>{formatMenuLabel(i, menu.label, forceLabels)}</MenuLabel>
          {festivalMenuPreface}
          <Menu menu={menu} withTitle={false} index={i} forceLabels={forceLabels} fullWidth />
        </div>
      ))}
      <ProductDetailsPanel defaultShow={['chefsMenuDescription']} restaurant={restaurant} sections={['restaurant', 'location']} />
    </Menus>
  )
}
